/**
 * 秒数转为 mm:ss
 * @param {*} duration 秒数
 */
export function duration2mmss (duration) {
  let sec = parseInt(duration)
  let minutes = Math.floor(sec / 60)
  let seconds = sec - minutes * 60

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return minutes + ':' + seconds
}

/**
 * 秒数转为 hh:mm:ss
 * @param {*} duration 秒数
 */
export function duration2hhmmss (duration) {
  let sec = parseInt(duration)
  let hours = Math.floor(sec / 60 / 60)
  let minutes = Math.floor(sec / 60 % 60)
  let seconds = Math.floor(sec % 60)

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}

/**
 * @param time datetime
 * @param cFormat
 * @returns {*} yyyy-mm-dd hh:min:ss
 */
export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

/**
 * 随机获取一个整数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 */
export const rnd = (min = 0, max = 1) => {
  const random = Math.floor(Math.random() * (max - min + 1) + min)
  return random
}

/**
 * 随机获取数组内一个数据
 * @param {Array} self 数组
 */
export const rndone = (self = []) => {
  return self.length > 0 ? self[rnd(0, self.length - 1)] : ''
}

/**
 * 获取时间戳 以分钟为最小单位
 */
export const getTimestampOfMinute = () => {
  let d = new Date()
  d.setSeconds(0)
  return Math.round(d.getTime() / 1000)
}

/**
 * 加载图片onload事件
 * @param {*} url 图片地址
 * @returns 图片大小
 */
export function loadImage (url) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height
      })
    }
    img.onerror = reject
    img.src = url
  })
}
