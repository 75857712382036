<template>
  <div>
    <div class="tuwen-detail">
      <!-- 标题 -->
      <div class="tuwen-detail-title">{{detailTitle}}</div>
      <!-- 分享和时间 -->
      <div class="tuwen-detail-other">
        <div class="tuwen-detail-share">{{detailshareNum}}分享</div>
        <div class="tuwen-detail-time">{{time}}</div>
      </div>
    </div>
    <div class="content" v-html="node"></div>
    <div class="web-info">
      <div>{{$store.state.urlInfo.home_record}}</div>
      <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">{{$store.state.urlInfo.icp}}</a>
    </div>
    <!-- 分享弹窗 -->
    <div class="share-popup" v-if="isShowPopup" @click="onClickPopup()">
      <!-- 曲线 -->
      <div class="curve">
        <img src="@/assets/images/tuwen/icon_curve.png" alt="">
      </div>
      <div class="share-popup-text">
        <!-- 星星 -->
        <img class="xing" src="@/assets/images/tuwen/icon_xing.png" alt="">
        <img class="two-xing" src="@/assets/images/tuwen/icon_two_xing.png" alt="">
        <div class="text">点击右上角“...”分享给 好友或微信群吧！</div>
      </div>
      <div class="share-popup-btn">我知道了</div>
    </div>
    <!-- 底部tab -->
    <!-- <tab-bars  @confirm="clickPopup()" :path="path"></tab-bars> -->
  </div>
</template>

<script>
import { parseTime } from '@/common/utils'
// import Tabbars from '@/components/common/Tabbars'
// import wx from 'weixin-js-sdk'
import { mapState } from 'vuex'
export default {
  name: 'TuwenDetail',
  components: {
    // 'tab-bars': Tabbars
  },
  data () {
    return {
      isShowPopup: false, // 弹窗关闭
      detailTitle: '', // 标题
      detailshareNum: '',
      time: '',
      detailUpImage: '',
      path: 'detail',
      node: ''
    }
  },
  computed: {
    ...mapState([
      'config'
    ])
  },
  mounted () {
    // this.$hasLoad().then(() => {
    //   this.isPageLoading = false

    //   // 设置分享数据
    //   this._setShareInfo()

    //   // 设置右上角菜单显示状态
    //   this._setMenuItems()
    // })
    this.time = parseTime(new Date())
    this.node = this.$route.query.content
    this.detailTitle = this.$route.query.title
    this.detailshareNum = this.$route.query.shareNum
  },
  deactivated () {
    this.$destroy()
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    /**
     * 设置分享数据
     */
    // _setShareInfo () {
    //   let _this = this
    //   wx.ready(() => {
    //     wx.onMenuShareAppMessage({
    //       title: _this.config.get('globalConfig.shareCard.shareCardTitle') || '', // 分享标题
    //       desc: _this.config.get('globalConfig.shareCard.shareCardTxt') || '', // 分享描述
    //       link: `${window.location.href.split('#')[0]}?#/private-domain/dispatch?id=${_this.$route.query.id}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //       imgUrl: _this.config.get('globalConfig.shareCard.shareCardImage.url') || '', // 分享图标
    //       type: '', // 分享类型，music、video 或 link，不填默认为 link
    //       dataUrl: '', // 如果 type 是 music 或 video，则要提供数据链接，默认为空
    //       success: function () {
    //         // 用户点击了分享后执行的回调函数
    //         // _this._handleClickShare('appMessage')
    //       }
    //     })

    //     wx.onMenuShareTimeline({
    //       title: _this.config.get('globalConfig.shareCard.shareCardTitle'), // 分享标题
    //       link: `${window.location.href.split('#')[0]}?#/private-domain/dispatch?id=${_this.$route.query.id}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //       imgUrl: _this.config.get('globalConfig.shareCard.shareCardImage.url'), // 分享图标
    //       success: function () {
    //         // 用户点击了分享后执行的回调函数
    //         // _this._handleClickShare('timeline')
    //       }
    //     })
    //   })
    // },

    /**
     * 设置右上角菜单显示状态
     */
    // _setMenuItems () {
    //   wx.hideAllNonBaseMenuItem()

    //   // 显示右上角分享菜单
    //   wx.showMenuItems({
    //     menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
    //   })
    // },
    // 点击弹窗
    clickPopup () {
      this.isShowPopup = true
    },
    onClickPopup () {
      this.isShowPopup = false
    }
  }
}
</script>

<style scoped>
.tuwen-detail-title {
  padding: 0 20px;
  margin-top: 15px;
  font-size: 38px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}
.content {
  padding: 0 20px 200px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 50px;
}
.content /deep/ img {
  width: 694px;
  height: 372px;
  /* text-align: center; */
  border-radius: 5px;
}
.share-popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  /* opacity: 0.7; */
  position: fixed;
  top: 0;
  z-index: 100;
}
.tuwen-detail-other {
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.tuwen-detail-time {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
.tuwen-detail-share {
  background: #FCECEB;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #F44B50;
  padding: 5px;
  margin-right: 10px;
}

.share-popup-text {
  position: absolute;
  display: flex;
  left: 230px;
  align-items: center;
  top: 190px;
  width: 445px;
  height: 131px;
  background: #F5575C;
  border-radius: 66px;
}
.text {
  margin: 0 auto;
  width: 347px;
  height: 81px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 550;
  color: #FFFFFF;
  /* line-height: 50px; */
}
.curve {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width:  186px;
  height:  193px;
}
.curve img {
  width: 100%;
  height: 100%;
}
.share-popup-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 300px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 40px;
  border: 3px solid #FFFFFF;
  border-radius: 60px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.xing {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  bottom: -10px;
}
.two-xing {
  width: 64px;
  height: 76px;
  position: absolute;
  top: 0;
  left: -50px;
}
.web-info {
  width: 100%;
  height: 100px;
  background: #fff;
  /* position: absolute;
  bottom: 0;
  left: 0; */
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
